<template>
    <div style="font-family: sans-serif; font-size: 13px; line-height: initial; color: initial">
        <table style="width: 100%; page-break-before: always">
            <tbody>
                <tr>
                    <td width="30%">
                        <img src="../assets/logo.png" width="100" />
                        <p style="font-size: 10px">
                            <strong>Công ty TNHH SERVIER (VIỆT NAM)</strong>
                        </p>
                    </td>
                    <td width="40%">
                        <p style="text-align: center; margin-bottom: 0; font-size: 12px">
                            <strong>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</strong>
                            <br />Độc lập – Tự do – Hạnh phúc
                        </p>
                        <hr />
                        <p
                            style="
                                text-align: center;
                                margin-top: 0;
                                color: #0070c0;
                                font-size: 12px;
                            "
                        >
                            <strong>SOCIALIST REPUBLIC OF VIETNAM</strong>
                            <br />Independence – Freedom – Happiness
                        </p>
                    </td>
                    <td width="30%" align="center">
                        <p style="text-align: right; font-size: 10px; font-weight: bold">
                            Mẫu số (form No): <span style="color: red">CTT56</span><br />Ký hiệu
                            (Serial No):
                            <span style="color: red"
                                >SV/{{
                                    new Intl.DateTimeFormat('vi', {
                                        year: 'numeric',
                                    }).format(new Date(receipt.created))
                                }}/T</span
                            ><br />Số (No):
                            <span style="color: red"
                                ><strong>{{ receipt.numero }}</strong></span
                            >
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <h1
            style="
                text-align: center;
                margin-top: 24px;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: bold;
            "
        >
            CHỨNG TỪ KHẤU TRỪ THUẾ THU NHẬP CÁ NHÂN <br /><small style="color: #0070c0"
                >CERTIFICATE OF PERSONAL INCOME TAX WITHHOLDING</small
            >
        </h1>
        <p style="text-align: center; margin-top: 8px; font-weight: bold">
            <span style="color: red">Liên {{ original }}/ Original {{ original }}</span
            >:
            <strong v-if="original == 1"
                >Lưu <span style="color: #0070c0">(For checking)</span></strong
            >
            <strong v-if="original == 2"
                >Giao cho người bị khấu trừ thuế
                <span style="color: #0070c0">(For tax payer)</span></strong
            >
        </p>
        <h2 style="font-size: 15px; margin: 24px 0 16px; font-weight: bold">
            I. THÔNG TIN TỔ CHỨC TRẢ THU NHẬP
        </h2>
        <p>
            <strong>[01]</strong> Tên tổ chức trả thu nhập
            <span style="color: #0070c0">(Name of the income paying organization)</span>:
            <strong>CÔNG TY TNHH SERVIER (VIỆT NAM)</strong>
        </p>
        <p>
            <span style="display: inline-block; vertical-align: middle">
                <strong>[02]</strong> Mã số thuế: <br />
                <span style="color: #0070c0; display: inline-block; padding-top: 3px"
                    >(Tax identification number)</span
                >
            </span>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    font-size: 19px;
                    font-family: serif;
                    margin-left: 4px;
                    height: 28px;
                "
            >
                <strong
                    v-for="(number, index) in svntaxcode"
                    v-bind:key="index"
                    style="
                        border: 1px solid;
                        display: inline-block;
                        margin-right: -1px;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                    "
                    >{{ number }}</strong
                >
            </span>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    font-size: 19px;
                    font-family: serif;
                    margin-left: 4px;
                    height: 28px;
                "
            >
                <strong
                    v-for="(number, index) in svntaxcodeExt"
                    v-bind:key="index"
                    style="
                        border: 1px solid;
                        display: inline-block;
                        margin-right: -1px;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                    "
                    >{{ number }}</strong
                >
            </span>
        </p>
        <p>
            <strong>[03]</strong> Địa chỉ <span style="color: #0070c0">(Address)</span>: Lầu 15, Số
            138-142 Đường Hai Bà Trưng, Phường Đa Kao, Quận 1, TP Hồ Chí Minh, Việt Nam
        </p>
        <p>
            <strong>[04]</strong> Điện thoại <span style="color: #0070c0">(Telephone number)</span>:
            28 38 238 932
        </p>
        <h2 style="font-size: 15px; margin: 24px 0 16px; font-weight: bold">
            II. THÔNG TIN NGƯỜI NỘP THUẾ
        </h2>
        <p>
            <strong>[05]</strong> Họ và tên <span style="color: #0070c0">(Full name)</span>:
            <strong style="text-transform: uppercase">{{ receipt.fullname }}</strong>
        </p>
        <p>
            <span style="display: inline-block; vertical-align: middle">
                <strong>[06]</strong> Mã số thuế: <br />
                <span style="color: #0070c0; display: inline-block; padding-top: 3px"
                    >(Tax identification number)</span
                >
            </span>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    font-size: 19px;
                    font-family: serif;
                    margin-left: 4px;
                    height: 28px;
                "
            >
                <strong
                    v-for="(number, index) in rectaxcode"
                    v-bind:key="index"
                    style="
                        border: 1px solid;
                        display: inline-block;
                        margin-right: -1px;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                    "
                    >{{ number }}</strong
                >
            </span>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    font-size: 19px;
                    font-family: serif;
                    margin-left: 4px;
                    height: 28px;
                "
            >
                <strong
                    v-for="(number, index) in rectaxcodeExt"
                    v-bind:key="index"
                    style="
                        border: 1px solid;
                        display: inline-block;
                        margin-right: -1px;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                    "
                    >{{ number }}</strong
                >
            </span>
        </p>
        <p>
            <strong>[07]</strong> Quốc tịch <span style="color: #0070c0">(Nationality)</span>:
            <strong>{{ receipt.nationality }}</strong>
        </p>
        <p>
            <strong>[08]</strong> Cá nhân cư trú
            <span style="color: #0070c0">(Resident individual)</span>
            <strong style="font-size: 18px; line-height: 12px">{{
                receipt.resident === 'Yes' ? '☑' : '☐'
            }}</strong>
            &nbsp; &nbsp; <strong>[09]</strong> Cá nhân không cư trú
            <span style="color: #0070c0">(Non-resident Individual)</span>
            <strong style="font-size: 18px; line-height: 12px">{{
                receipt.resident === 'No' ? '☑' : '☐'
            }}</strong>
        </p>
        <p>
            <strong>[10]</strong> Địa chỉ hoặc điện thoại liên hệ
            <span style="color: #0070c0">(Contact Address or Telephone Number)</span>:
            <strong>{{ receipt.contact_address_phone }}</strong>
        </p>
        <p>
            <strong>[11]</strong> Số CMND hoặc số hộ chiếu
            <span style="color: #0070c0">(ID/Passport Number)</span>:
            <strong>{{ receipt.id_number }}</strong>
        </p>
        <p>
            <strong>[12]</strong> Nơi cấp <span style="color: #0070c0">(Place of issue)</span>:
            <strong>{{ receipt.id_place }}</strong> &nbsp; &nbsp; <strong>[13]</strong> Ngày cấp
            <span style="color: #0070c0">(Date of issue)</span>:
            <strong>{{
                new Intl.DateTimeFormat('vi', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(receipt.id_issued))
            }}</strong>
        </p>
        <h2 style="font-size: 15px; margin: 24px 0 16px; font-weight: bold">
            III. THÔNG TIN THUẾ THU NHẬP CÁ NHÂN KHẤU TRỪ
        </h2>
        <p>
            <strong>[14]</strong> Khoản thu nhập
            <span style="color: #0070c0">(Type of income)</span>:
            <strong>{{ receipt.income_type }}</strong>
        </p>
        <p>
            <strong>[15]</strong> Thời điểm trả thu nhập
            <span style="color: #0070c0">(Time of income payment)</span>: từ tháng
            <span style="color: #0070c0">(month) </span>
            <strong>{{
                new Intl.DateTimeFormat('vi', { month: '2-digit' }).format(
                    new Date(receipt.income_from),
                )
            }}</strong>
            đến tháng <span style="color: #0070c0">(month) </span>
            <strong>{{
                new Intl.DateTimeFormat('vi', { month: '2-digit' }).format(
                    new Date(receipt.payment_time),
                )
            }}</strong
            >, năm <span style="color: #0070c0">(year)</span>
            <strong>{{
                new Intl.DateTimeFormat('vi', { year: 'numeric' }).format(
                    new Date(receipt.payment_time),
                )
            }}</strong>
        </p>
        <p>
            <strong>[16]</strong> Tổng thu nhập chịu thuế phải khấu trừ
            <span style="color: #0070c0">(Total taxable income to be withheld)</span>:
            <strong>{{ receipt.income_gross }}</strong> VNĐ
        </p>
        <p>
            <strong>[17]</strong> Tổng thu nhập tính thuế
            <span style="color: #0070c0">(Total tax calculation income)</span>:
            <strong v-if="receipt.total_tax_calculation_income !== '0'">{{
                receipt.total_tax_calculation_income
            }}</strong>
            <span v-if="receipt.total_tax_calculation_income !== '0'"> VNĐ</span>
        </p>
        <p>
            <strong>[18]</strong> Số thuế thu nhập cá nhân đã khấu trừ
            <span style="color: #0070c0">(Amount of personal income tax withheld)</span>:
            <strong>{{ receipt.income_tax }}</strong> VNĐ
        </p>
        <table style="width: 100%; font-size: 13px; margin-top: 32px; page-break-after: always">
            <tbody>
                <tr>
                    <td width="40%"></td>
                    <td width="60%">
                        <p style="text-align: center">
                            Thành phố Hồ Chí Minh
                            <span style="color: #0070c0">(Ho Chi Minh city)</span>,
                            <br />
                            ngày <span style="color: #0070c0">(date)</span>
                            <strong>{{
                                new Intl.DateTimeFormat('vi', {
                                    day: '2-digit',
                                }).format(new Date(receipt.created))
                            }}</strong
                            >, tháng <span style="color: #0070c0">(month)</span>
                            <strong>{{
                                new Intl.DateTimeFormat('vi', {
                                    month: '2-digit',
                                }).format(new Date(receipt.created))
                            }}</strong
                            >, năm <span style="color: #0070c0">(year)</span>
                            <strong>{{
                                new Intl.DateTimeFormat('vi', {
                                    year: 'numeric',
                                }).format(new Date(receipt.created))
                            }}</strong>
                        </p>
                        <p style="text-align: center; font-weight: bolder">
                            ĐẠI DIỆN TỔ CHỨC TRẢ THU NHẬP
                            <span style="display: inline-block; color: #0070c0"
                                >(Income paying organization)</span
                            >
                        </p>
                        <p style="text-align: center">
                            Ký, đóng dấu
                            <span style="color: #0070c0">(Signature, seal)</span>
                        </p>
                        <p style="margin-top: 5px; font-size: 8px; text-align: center">
                            Theo giấy ủy quyền số 01/0521 ngày 01 tháng 03 năm 2021<br />
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p style="text-align: center; margin: 0">
                            <strong>{{ receipt.payment_sign }}</strong>
                        </p>
                        <p style="text-align: center; margin: 0">
                            {{ receipt.payment_title }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <p style="margin-top: 5px; font-size: 8px; text-align: center">
                            Chứng từ khấu trừ thuế thu nhập cá nhân được chấp nhận của cơ quan Thuế
                            theo thông báo số 701/TB-CTTPHCM ngày 12 tháng 01 năm 2022
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        original: Number,
        receipt: Object,
    },
    computed: {
        svntaxcode() {
            return '0307637504'.split('');
        },
        svntaxcodeExt() {
            return ['', '', ''];
        },
        rectaxcode() {
            return this.receipt.taxcode.split('');
        },
        rectaxcodeExt() {
            return ['', '', ''];
        },
    },
};
</script>

<style scoped>
p {
    line-height: 1;
}
</style>
