<template>
    <div style="font-family: serif; line-height: initial; color: initial">
        <table style="width: 100%; page-break-before: always">
            <tbody>
                <tr>
                    <td width="25%"></td>
                    <td width="50%">
                        <p
                            style="
                                text-align: center;
                                margin-top: 16px;
                                font-size: 14px;
                                font-weight: bold;
                            "
                        >
                            CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM <br />Độc lập – Tự do – Hạnh phúc
                        </p>
                        <hr style="max-width: 180px" />
                    </td>
                    <td width="25%" align="center">
                        <p
                            style="
                                text-align: center;
                                border: 1px solid;
                                display: inline-block;
                                padding: 3px;
                                font-size: 12px;
                            "
                        >
                            Mẫu số <strong>20/TXN-TNCN</strong> <br /><em
                                >(Ban hành kèm thông tư số <br />156/2013/TT-BTC ngày
                                <br />6/11/2013 của Bộ Tài chính)</em
                            >
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <h1 style="text-align: center; margin-bottom: 0; font-size: 24px; font-weight: bold">
            THƯ XÁC NHẬN THU NHẬP <br /><small style="color: #0070c0">CONFIRMATION LETTER</small>
        </h1>
        <p style="text-align: center; margin: 8px 0 32px">
            Năm/ <span style="color: #0070c0">Year</span>:
            <strong>{{
                new Intl.DateTimeFormat('vi', { year: 'numeric' }).format(
                    new Date(receipt.payment_time),
                )
            }}</strong>
        </p>
        <p>
            Thay mặt tổ chức, cá nhân trả thu nhập /<span style="color: #0070c0">On behalf of</span
            >: <strong>CTY TNHH SERVIER (VIỆT NAM)</strong>
        </p>
        <p>
            MST /<span style="color: #0070c0">Tax code</span>:
            <strong>0307637504</strong>
        </p>
        <p>
            Tôi xin xác nhận về việc /<span style="color: #0070c0">I would like to confirm for</span
            >:
        </p>
        <ol>
            <li>
                <p style="margin: 12px 0">
                    Họ và tên /Full name:
                    <strong style="text-transform: uppercase">{{ receipt.fullname }}</strong>
                </p>
            </li>
            <li>
                <p style="margin: 12px 0">
                    MST /Tax code: <strong>{{ receipt.taxcode }}</strong>
                </p>
            </li>
            <li>
                <p style="margin: 12px 0">
                    Ngày đến Việt Nam /<span style="color: #0070c0">Arrival Date</span>:
                    <strong>{{
                        receipt.arrival_date
                            ? new Intl.DateTimeFormat('vi', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                              }).format(new Date(receipt.arrival_date))
                            : ''
                    }}</strong>
                </p>
            </li>
            <li>
                <p style="margin: 12px 0">
                    Thu nhập trong giai đoạn /<span style="color: #0070c0"
                        >Incomes during the year</span
                    >:
                    <strong>{{
                        new Intl.DateTimeFormat('vi', { year: 'numeric' }).format(
                            new Date(receipt.payment_time),
                        )
                    }}</strong>
                </p>
                <p style="margin: 12px 0">
                    Từ ngày /From date:
                    <strong>{{
                        new Intl.DateTimeFormat('vi', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(new Date(receipt.income_from))
                    }}</strong>
                    <br />Đến ngày /To date:
                    <strong>{{
                        new Intl.DateTimeFormat('vi', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }).format(new Date(receipt.income_to))
                    }}</strong>
                </p>
                <p style="margin: 12px 0">
                    Tổng thu nhập trước thuế là /<span style="color: #0070c0"
                        >Total gross income</span
                    >
                    <strong>{{ receipt.income_gross }}</strong> VNĐ, trong đó /<span
                        style="color: #0070c0"
                        >Including</span
                    >:
                </p>
                <p style="margin: 12px 0">
                    a. Tại Việt Nam /<span style="color: #0070c0">In Vietnam</span>:
                    <strong>{{ receipt.income_gross }} VNĐ</strong> <br />b. Tại nước ngoài /<span
                        style="color: #0070c0"
                        >In foreign country</span
                    >: ..........
                </p>
            </li>
            <li>
                <p style="margin: 12px 0">
                    Các khoản bị khấu trừ tại Việt Nam (nếu có) /<span style="color: #0070c0"
                        >Deductions in Viet Nam (if any)</span
                    >:
                </p>
                <p style="margin: 12px 0">
                    - Thuế thu nhập cá nhân /<span style="color: #0070c0">Personal incomes tax</span
                    >: <strong>{{ receipt.income_tax }} VNĐ</strong>
                </p>
                <p style="margin: 12px 0">
                    - BHXH hoặc các loại bảo hiểm bắt buộc /<span style="color: #0070c0"
                        >Social insurances</span
                    >: <strong>{{ receipt.insurances }} VNĐ</strong>
                </p>
                <p style="margin: 12px 0">
                    - Các khoản bị khấu trừ khác /<span style="color: #0070c0"
                        >Other deductions (if any)</span
                    >: Không có /<span style="color: #0070c0">No</span>
                </p>
            </li>
            <li>
                <p v-if="!hasHouseRental" style="margin: 12px 0">
                    Tiền thuê nhà tại Việt Nam /
                    <span style="color: #0070c0"> House rental in Vietnam </span>
                    : Không có / <span style="color: #0070c0">No</span>
                </p>
                <p v-else style="margin: 12px 0">
                    Tiền thuê nhà tại Việt Nam /
                    <span style="color: #0070c0"> House rental in Vietnam </span>
                    : <strong>{{ receipt.house_rental }} VNĐ</strong>
                </p>
            </li>
        </ol>
        <p>
            Tôi cam đoan số liệu khai trên là đúng và chịu trách nhiệm trước pháp luật về những số
            liệu đã khai /<span style="color: #0070c0"
                >I will take full responsibilities under the law for the correctness of data
                declared</span
            >
            ./.
        </p>
        <table style="width: 100%; page-break-after: always">
            <tbody>
                <tr>
                    <td width="50%"></td>
                    <td width="50%">
                        <p style="text-align: center">
                            TP. HCM, ngày
                            {{
                                new Intl.DateTimeFormat('vi', {
                                    day: '2-digit',
                                }).format(new Date(receipt.created))
                            }}
                            tháng
                            {{
                                new Intl.DateTimeFormat('vi', {
                                    month: '2-digit',
                                }).format(new Date(receipt.created))
                            }}
                            năm
                            {{
                                new Intl.DateTimeFormat('vi', {
                                    year: 'numeric',
                                }).format(new Date(receipt.created))
                            }}
                        </p>
                        <p style="text-align: center; font-weight: bolder">
                            ĐẠI DIỆN HỢP PHÁP <br /><small>CỦA TỔ CHỨC, CÁ NHÂN TRẢ THU NHẬP</small>
                        </p>
                        <p style="margin-top: 5px; font-size: 8px; text-align: center">
                            Theo giấy ủy quyền số 01/0521 ngày 01 tháng 03 năm 2021<br />
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p style="text-align: center; margin: 0">
                            <strong>{{ receipt.payment_sign }}</strong>
                        </p>
                        <p style="text-align: center; margin: 0">
                            {{ receipt.payment_title }}
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        receipt: Object,
    },
    computed: {
        hasHouseRental() {
            return !!parseInt(this.receipt.house_rental);
        },
    },
};
</script>
