<template>
    <div class="header">
        <div class="container">
            <div class="container-flex">
                <div class="logo">
                    <router-link to="/"><img alt="logo" src="../assets/logo.png"></router-link>
                </div>
                <h1 class="title">PIT SYSTEM</h1>
                <div class="links">
                    <a-button type="link" @click="logout">Logout</a-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        methods: {
            logout: function() {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                this.$router.push('/login');
            }
        }
    }
</script>
<style lang="scss">
    $tablet-width: 768px;
    $desktop-width: 1024px;

    @mixin tablet {
        @media (min-width: #{$tablet-width}) {
            @content;
        }
    }

    @mixin desktop {
        @media (min-width: #{$desktop-width}) {
            @content;
        }
    }

    .header {
        background-color: #F8F8F8 !important;
        box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);

        .logo {
            width: 90px;
            padding-top: 8px;
            padding-bottom: 8px;

            @include tablet {
                width: 160px;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .title {
            margin-top: 0.5rem;
        }
    }
</style>